@import '../summernote/font.scss';
@import '../summernote/common.scss';

.note-toolbar {
  background: $background-color;
}

.note-btn-group .note-btn {
  border-color: $border-color;
  padding: 0.28rem 0.65rem;
  font-size: 13px;
}
